.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  position: relative;
  z-index: 3;
  padding: 0 42px;
  height: 84px;

  &-items__menu {
    display: flex;
    align-items: center;
    justify-content: center;

    &-list {
      color: white;
      list-style: none;
      font-size: 16px;
      font-weight: 400;
      font-family: "Dm Sans", sans-serif;
      transition: 0.3s all ease;
      border-bottom: 0.5px solid transparent;
      padding: 18px;

      &--link {
        text-decoration: none;
      }

      &--btn {
        cursor: pointer;
        background-color: transparent;
        border: none;
      }
    }
  }
  &-item {
    display: inline-flex;
    justify-content: center;
    gap: 8px;
    align-items: center;
    color: #fff;
    font-size: 16px;
    line-height: 1.6em;
    font-weight: 400;
    white-space: nowrap;

    &-drop-down {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;
      color: #000;
      white-space: nowrap;
      overflow: hidden;
      font-size: 1rem;
    }

    &--icon {
    }
  }

  &:after {
    content: "";
    height: 1px;
    border-bottom: 0 solid transparent;
    width: calc(100% - (44px * 2));
    top: auto;
    bottom: 0;
    left: 44px;
    position: absolute;
    border-bottom-color: rgba(255, 255, 255, 0.22);
    border-bottom-width: 1px;
  }
}

.parentItem {
  border-bottom: 0.5px solid transparent;

  &:hover {
    animation: 0.3s navbar-item linear forwards;
    border-bottom: 0.5px solid white;
  }
}

a.active {
  display: inline-block;
  border-bottom: 0.5px solid white;
}

.logo-image {
  width: 132px;
  max-height: 100%;
  height: 60px;
  border: none;
}

@keyframes navbar-item {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}

// mobile

.navbar-mobile {
  margin-right: 40px;
  display: none;
}

.svg {
  max-height: 100%;
  height: 50px;
  width: auto;
  margin: 10px;
  cursor: pointer;
  border: none;
  transition: all 0.3s ease-in;
}

@media (max-width: 1024px) {
  .navbar {
    background-color: #fff;

    &-item {
      &--icon {
        fill: #000 !important;
      }
    }
  }
  .navbar-items-mobile-dropdown {
    &__menu-list {
      &--btn {
        border: none;
        background-color: transparent;
        color: #000;
      }
    }
  }
  .navbar-items {
    display: none;
  }
  .navbar-mobile {
    display: block;
  }
  a.active {
    border-bottom: 0.5px solid #000;
  }

  .parentItem {
    &:hover {
      border-bottom: 0.5px solid #000;
    }
  }
}

@media (min-width: 1024px) {
  .navbar-items-mobile-dropdown {
    display: none;
  }
}

@media (max-width: 668px) {
.navbar-mobile{
  margin: 0;
}
}
.hidden {
  height: 0;
}

.visible {
  height: 4vh;
}

.navbar-items-mobile-dropdown {
  width: 100%;
  background-color: #fff;
  transition: 0.5s all;
  position: absolute;
  top: 84px;
  left: 0;
  z-index: 3;
  overflow: hidden;

  &__menu {
    display: flex;
    align-items: self-start;
    justify-content: center;
    flex-direction: column;
    gap: 15px;
    padding: 40px 50px;
    border-top: 1px solid #e1e1e1;

    &-list {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      width: 100%;
      color: black;
      list-style: none;
      font-size: 18px;
      font-weight: 400;
      font-family: "Dm Sans", sans-serif;
      transition: 0.3s all ease;
      border-bottom: 0.5px solid transparent;

      &__arrow{
        cursor: pointer;
        transition: 0.3s all ease;
      }
    }
  }
}

.visible-dropdown {
  transition: 0.5s all ease-out;
}
