* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  transition: all .4s ease;
}


@media screen and (max-width: 1024px) {
h1{
  font-size: 2rem !important;
}
h2{
  font-size: 1.4rem !important;
}

h3{
  font-size: 1.4rem !important;
}
}
