.parentItem-container{
    max-width: fit-content;
}
.parentItem {
  position: relative;
}

.childrenItem {
  width: 320px;
  min-width: fit-content;
  z-index: 10;
  position: absolute;
  margin-top: 20px;
  margin-right: 30px;
  background-color: #fff;
  padding: 15px;

  &__list {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    width: max-content !important;

    &--text {
      color: #000;
    }

    &--link{
      max-width: fit-content;
      margin: .5rem;
        &:hover{
            animation: 0.2s navbar-item linear forwards;
            border-bottom: 0.5px solid #000;
        }
    }

  }
}

@media (max-width: 1024px) {
    .childrenItem{
        position: initial;
        margin: 0;
    }
  }
